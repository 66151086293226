import * as React from "react"
import TermsAndConditions from "@components/TermsAndConditions/TermsAndConditions"
import Seo from "@components/seo"

const TermsAndConditionsPage = () => (
  <>
    <Seo title="Terms and Conditions" />
    <TermsAndConditions />
  </>
)

export default TermsAndConditionsPage
