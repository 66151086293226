import React from "react"
import { detectMob } from "@src/Helper"

const TermsAndConditions = () => {
  return (
    <div
      id={`${detectMob() && "m-termsAndConditions"}`}
      className="terms-and-conditions"
    >
      <div className="terms-and-conditions__jumbotron">
        <div className="container pad-t-175 pad-b-100">
          <h1 className="terms-and-conditions__jumbotron__title heading-1">
            Syarat &amp; Ketentuan
          </h1>
        </div>
      </div>
      <div className="container pad-t-40 pad-b-70 pad-x-40">
        <div className="terms-and-conditions__item">
          {/* 1 */}
          <h5 className="heading-5">1. Ketentuan Umum</h5>
          <p className="body">Selamat datang di Sociopipe.</p>
          <p className="body">
            Ketentuan Penggunaan ini berfungsi sebagai perjanjian antara
            pengguna (<span className="text-700">“Anda”</span>) dan PT.
            Eksekutif Media Utama (<span className="text-700">“Kami”</span>
            ), sebuah perseroan terbatas yang didirikan dan beroperasi secara
            sah berdasarkan hukum negara Republik Indonesia dan berdomisili di
            Medan, Sumatera Utara, Indonesia. Syarat dan ketentuan ini dibuat
            untuk mengatur penggunaan Anda atas website yang kami kelola (
            <a
              className="terms-and-conditions__link"
              href="https://www.sociopipe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.sociopipe.com
            </a>{" "}
            dan www.pipeho.me), konten dan produk yang disediakan oleh kami
            (selanjutnya, secara bersama-sama disebut sebagai{" "}
            <span className="text-700">“Aplikasi”</span>), serta penggunaan
            layanan yang tersedia pada Aplikasi (
            <span className="text-700">“Layanan”</span>).
          </p>
          <p className="body">
            Dengan menyetujui Ketentuan Penggunaan ini, Anda juga menyetujui
            Kebijakan Privasi kami (
            <span className="text-700">“Perjanjian”</span>
            ). Persetujuan ini juga menyatakan bahwa Anda telah membaca,
            mengerti, dan setuju untuk terikat pada Perjanjian ini.
          </p>
        </div>

        {/* 2 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">2. Penggunaan Aplikasi dan Layanan</h5>
          <p className="body">
            Akses dan penggunaan Aplikasi tunduk pada Ketentuan Penggunaan ini.
          </p>
          <p className="body">
            Anda memiliki kebebasan penuh untuk memilih menggunakan Aplikasi
            atau aplikasi lain, menggunakan Layanan yang tersedia pada Aplikasi
            atau tidak, atau berhenti menggunakan Aplikasi.
          </p>
          <p className="body">
            Kami hanya memfasilitasi Anda untuk menggunakan Layanan yang Anda
            perlukan dengan menyediakan Aplikasi. Semua Layanan disediakan
            secara langsung oleh pihak ketiga independen yang setuju menjadi
            penyedia layanan Kami dengan skema kemitraan atau skema lainnya (
            <span className="text-700">“Penyedia Layanan”</span>).
          </p>
        </div>

        {/* 3 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">3. Pembukaan dan Akses Akun</h5>
          <p className="body">
            Sebelum menggunakan Aplikasi, Anda harus menyetujui Ketentuan
            Penggunaan ini dan Kebijakan Privasi kami, dan mendaftarkan diri
            Anda dengan memberikan informasi yang dibutuhkan oleh Kami. Sebagai
            catatan tambahan bahwa Anda dapat mengubah informasi data diri Anda
            pada fitur pengaturan dalam Aplikasi.
          </p>
          <p className="body">
            Setelah melakukan pendaftaran, sistem Kami akan mengirimkan email
            verifikasi secara otomatis. Anda perlu melakukan verifikasi dengan
            menekan tombol verifikasi yang terdapat di dalam email tersebut.
            Perlu diingat bahwa jika Anda tidak melakukan verifikasi email, maka
            akan ada beberapa fitur yang tidak dapat diakses sampai Anda
            melakukan verifikasi terhadap email yang Anda daftarkan di sistem
            Kami.
          </p>
          <p className="body">
            Setelah melakukan verifikasi, sistem Kami akan membuatkan akun
            Sociopipe pribadi (<span className="text-700">“Akun”</span>) untuk
            Anda yang dapat digunakan untuk menggunakan Aplikasi dan
            memanfaatkan Layanan kami melalui situs web. Email yang Anda gunakan
            untuk mendaftarkan diri ke sistem kami melekat pada Akun Anda
            sehingga Anda tidak bisa membuat Akun baru dengan email yang sudah
            didaftarkan. Hal yang sama juga berlaku apabila di kemudian hari
            Anda mengubah nomor telepon genggam Anda pada menu pengaturan di
            Aplikasi.
          </p>
          <p className="body">
            Dalam hal Anda telah keluar dari Akun Anda, maka Anda perlu
            memasukan email yang Anda berikan pada saat mendaftarkan diri Anda.
          </p>
        </div>

        {/* 4 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">4. Akun Anda</h5>
          <p className="body">
            Akun Anda hanya dapat digunakan oleh Anda dan tidak bisa dialihkan
            kepada orang lain dengan alasan apapun.
          </p>
          <p className="body">
            Keamanan dan kerahasiaan Akun Anda, termasuk nama, alamat email
            terdaftar, username, kata sandi hingga tautan verifikasi email yang
            dihasilkan dan dikirim oleh sistem Kami sepenuhnya merupakan
            tanggung jawab pribadi Anda. Semua kerugian dan risiko yang ada
            akibat kelalaian Anda menjaga keamanan dan kerahasiaan sebagaimana
            disebutkan ditanggung oleh Anda sendiri. Dalam hal demikian, Kami
            akan menganggap setiap penggunaan atau pesanan yang dilakukan
            melalui Akun Anda sebagai permintaan yang sah dari Anda.
          </p>
          <p className="body">
            Segera beritahukan Kami jika Anda mengetahui atau menduga bahwa Akun
            Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami
            akan melakukan tindakan yang Kami anggap perlu dan dapat Kami
            lakukan terhadap penggunaan tanpa persetujuan tersebut.
          </p>
        </div>

        {/* 5 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">5. Informasi Pribadi</h5>
          <p className="body">
            Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian
            informasi pribadi Anda yang Anda berikan ketika membuka Akun tunduk
            pada Kebijakan Privasi, yang merupakan bagian yang tidak terpisahkan
            dari Ketentuan Penggunaan ini.
          </p>
        </div>

        {/* 6 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">6. Konten, Informasi dan Promosi</h5>
          <p className="body">
            Kami atau pihak lain yang bekerja sama dengan Kami dapat menyediakan
            Konten Pihak Ketiga yang dapat Anda temukan pada Aplikasi. Dalam hal
            Konten Pihak Ketiga disediakan oleh pihak lain yang bekerja sama
            dengan Kami (
            <span className="text-700">“Penyedia Konten Pihak Ketiga”</span>),
            Kami tidak bertanggung jawab atas bagian apapun dari isi Konten
            Pihak Ketiga. Akses atau penggunaan Anda terhadap Konten Pihak
            Ketiga tersebut merupakan bentuk persetujuan Anda untuk tunduk pada
            syarat dan ketentuan yang ditetapkan oleh Kami atau Penyedia Konten
            Pihak Ketiga, termasuk terhadap Kebijakan Privasi Kami atau Penyedia
            Konten Pihak Ketiga.
          </p>
          <p className="body">
            <span className="text-700">Konten Pihak Ketiga</span> adalah setiap
            dan/atau seluruh informasi dan penawaran barang dan/atau jasa, yang
            dibuat dan/atau disusun dan/atau dikembangkan dan/atau dikelola oleh
            Penyedia Konten Pihak Ketiga termasuk namun tidak terbatas pada teks
            atau tulisan, gambar, quotes atau kutipan, foto, ilustrasi, animasi,
            video, rekaman suara atau musik, judul, deskripsi dan/atau setiap
            data dalam bentuk apapun yang disediakan oleh Penyedia Konten Pihak
            Ketiga untuk ditampilkan pada Aplikasi Sociopipe atau Pipehome,
            termasuk setiap tautan yang menghubungkan kepadanya. Untuk
            menghindari keraguan, Konten Pihak Ketiga mencakup pula setiap
            dan/atau seluruh penawaran barang dan/atau jasa, informasi, data,
            berita aktual, tulisan, gambar, kutipan, foto, ilustrasi, animasi,
            video, rekaman suara, yang diperoleh Penyedia Konten Pihak Ketiga
            dari pihak ketiga, dimana Penyedia Konten Pihak Ketiga telah
            memiliki kewenangan untuk menggunakan dan mendistribusikan konten
            tersebut.
          </p>
          <p className="body">
            Kami atau pihak lain yang bekerja sama dengan Kami dapat memberikan
            penawaran atau promosi (
            <span className="text-700">“Penawaran”</span>) yang dapat ditukar
            dengan barang, Layanan atau manfaat lain terkait dengan penggunaan
            Aplikasi. Dalam hal Penawaran disediakan oleh pihak lain yang
            bekerja sama dengan Kami (
            <span className="text-700">“Penyedia Penawaran”</span>), Kami tidak
            bertanggung jawab atas bagian apapun dari isi Penawaran tersebut.
            Akses atau penggunaan Anda terhadap Penawaran merupakan bentuk
            persetujuan Anda untuk tunduk pada syarat dan ketentuan yang
            ditetapkan oleh Kami atau Penyedia Penawaran, termasuk terhadap
            Kebijakan Privasi Kami atau Penyedia Penawaran.
          </p>
          <p className="body">
            Semua informasi, Penawaran yang terdapat pada Aplikasi hanya
            dimaksudkan untuk memberikan Anda pengalaman terbaik ketika
            menggunakan Aplikasi atau Layanan. Anda tidak boleh menyalahgunakan
            Penawaran yang Anda terima selama penggunaan Aplikasi atau Layanan.
          </p>
          <p className="body">
            Anda setuju untuk menggunakan Penawaran tersebut sesuai dengan
            syarat, ketentuan, dan maksud dari pemberian Penawaran dan tidak
            akan menyalahgunakan, menggandakan, menguangkan, mengalihkan,
            menggunakan untuk kepentingan komersial atau mengambil keuntungan
            dengan tidak wajar dari promosi tersebut dengan bentuk atau cara
            apapun.
          </p>
          <p className="body">
            Anda memahami bahwa Penawaran tidak dapat ditukar dengan uang tunai,
            memiliki masa keberlakuan yang terbatas dan tunduk pada ketentuan
            yang berlaku untuk setiap Penawaran tersebut.
          </p>
        </div>

        {/* 7 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">7. Biaya dan Pembayaran</h5>
          <p className="body">
            Pengunduhan perangkat lunak dan penggunaan Aplikasi adalah bebas
            biaya. Namun, Kami dapat mengenakan biaya untuk penggunaan fitur
            tertentu pada Aplikasi di kemudian hari.
          </p>
          <p className="body">
            Layanan yang tersedia pada Aplikasi dikenakan biaya yang dapat Anda
            temukan pada Aplikasi sebelum Anda memesan Layanan tersebut. Kami
            dapat mengubah atau memperbaharui biaya dari waktu ke waktu
            berdasarkan faktor tertentu, antara lain lokasi, waktu, jenis
            Layanan dan peraturan perundang-undangan yang berlaku. Kami juga
            dapat membebankan harga dan/atau biaya dengan besaran tertentu
            sebagai pembayaran untuk penggunaan Aplikasi yang dapat dipungut
            oleh Kami, afiliasi Kami, atau Penyedia Layanan.
          </p>
          <p className="body">
            Anda dapat melakukan pembayaran terhadap Layanan, Konten Pihak
            Ketiga atau Penawaran yang Anda akses atau gunakan melalui metode
            pembayaran elektronik, yang disediakan oleh pihak ketiga independen
            (<span className="text-700">“Penyedia Metode Pembayaran”</span>),
            pada Aplikasi, termasuk uang elektronik, fasilitas pinjaman, tagihan
            perusahaan (dengan menggunakan Akun pengguna yang sah yang terhubung
            dengan akun pemberi pekerjaan Anda) kartu debit atau kredit,
            rekening bank, atau metode pembayaran lain (
            <span className="text-700">“Metode Pembayaran”</span>) yang dapat
            berubah sewaktu-waktu berdasarkan kebijakan kami sepenuhnya.
          </p>
          <p className="body">
            Untuk dapat melakukan pembayaran melalui Metode Pembayaran, Anda
            harus terdaftar secara resmi pada Penyedia Metode Pembayaran yang
            Anda pilih dan menggunakan kredensial pembayaran Anda sendiri. Dalam
            hal Anda menggunakan kredensial terdaftar pihak lain, Anda
            bertanggung jawab secara penuh atas semua perizinan yang diperlukan
            dan semua kerugian atau sengketa yang timbul antara Anda dan pihak
            lain tersebut, baik karena kelalaian atau kesalahan Anda, Kami,
            Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran
            atau Penyedia Metode Pembayaran.
          </p>
          <p className="body">
            Kami berhak menolak atau menunda untuk meneruskan permintaan
            pembayaran Anda melalui Metode Pembayaran karena alasan tertentu,
            termasuk namun tidak terbatas pada adanya indikasi atau Kami
            mempunyai alasan yang cukup untuk menduga adanya kecurangan,
            penipuan, pelanggaran Ketentuan Penggunaan, pelanggaran atas
            peraturan perundang-undangan yang berlaku termasuk yang terkait
            dengan alat pembayaran menggunakan kartu, uang elektronik,
            pemrosesan transaksi pembayaran, anti pencucian uang, korupsi dan
            pencegahan pendanaan terorisme, atau tindakan lain yang tidak wajar
            atau mencurigakan, termasuk belum dipenuhinya kewajiban Anda kepada
            Kami.
          </p>
        </div>

        {/* 8 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">8. Perangkat Lunak Aplikasi</h5>
          <p className="body">
            Kami hanya menyediakan perangkat lunak Aplikasi yang resmi pada
            pasar digital resmi, seperti Google Play Store atau Apple App Store,
            dan untuk digunakan hanya pada perangkat telepon genggam atau
            tablet. Mengunduh Aplikasi dari tempat lain selain pasar digital
            resmi dan/atau ke dalam perangkat lain selain telepon genggam atau
            tablet merupakan pelanggaran terhadap Ketentuan Penggunaan ini dan
            terhadap hak kekayaan intelektual Kami.
          </p>
        </div>

        {/* 9 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">9. Kekayaan Intelektual</h5>
          <p className="body">
            Aplikasi dan Layanan, termasuk namun tidak terbatas pada, nama,
            logo, kode program, desain, merek dagang, teknologi, basis data,
            proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan
            hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum
            Republik Indonesia yang terdaftar baik atas nama Kami ataupun
            afiliasi Kami. Kami (dan pemberi lisensi Kami) memiliki seluruh hak
            dan kepentingan atas Aplikasi dan Layanan, termasuk seluruh hak
            kekayaan intelektual terkait dengan seluruh fitur yang terdapat
            didalamnya dan hak kekayaan intelektual terkait.
          </p>
          <p className="body">
            Tunduk pada Ketentuan Penggunaan ini, Kami memberikan Anda lisensi
            terbatas yang tidak eksklusif, dapat ditarik kembali, tidak dapat
            dialihkan (tanpa hak sublisensi) untuk (i) mengunduh, mengakses, dan
            menggunakan Aplikasi, sebagaimana adanya, hanya pada perangkat
            berupa telepon genggam dan/atau tablet pribadi Anda dan hanya untuk
            keperluan terkait penggunaan Layanan, dan (ii) mengakses atau
            menggunakan konten, informasi dan materi terkait yang tersedia pada
            Aplikasi hanya untuk kepentingan pribadi dan bukan tujuan komersial.
            Hak dan hak istimewa lainnya yang tidak secara tegas diberikan dalam
            Ketentuan Penggunaan ini, adalah hak Kami atau pemberi lisensi Kami.
          </p>
          <p className="body">
            Setiap penggandaan, distribusi, pembuatan karya turunan, penjualan
            atau penawaran untuk menjual, penampilan baik sebagian atau
            seluruhnya, serta penggunaan Aplikasi dan/atau Layanan, baik secara
            digital atau lainnya atau pada perangkat selain telepon genggam atau
            tablet, termasuk namun tidak terbatas pada penggunaan aplikasi
            tambahan seperti aplikasi modifikasi, emulator, dan lain-lain, yang
            menyimpang dari Ketentuan Penggunaan ini, maupun tujuan penggunaan
            yang ditentukan oleh Kami, merupakan pelanggaran terhadap hak
            kekayaan intelektual Kami.
          </p>
          <p className="body mb-2">Anda tidak boleh:</p>
          <ol>
            <li>
              <p className="body mb-0">
                Menghapus setiap pemberitahuan hak cipta, merek dagang atau
                pemberitahuan hak milik lainnya yang terkandung dalam Aplikasi;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Menyalin, memodifikasi, mengadaptasi, menerjemahkan, membuat
                karya turunan dari, mendistribusikan, memberikan lisensi,
                menjual, mengalihkan, menampilkan di muka umum baik sebagian
                maupun seluruhnya, merekayasa balik (reverse engineer),
                mentransmisikan, memindahkan, menyiarkan, menguraikan, atau
                membongkar bagian manapun dari atau dengan cara lain
                mengeksploitasi Aplikasi (termasuk perangkat lunak, fitur dan
                Layanan di dalamnya);
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Memberikan lisensi, mensublisensikan, menjual, menjual kembali,
                memindahkan, mengalihkan, mendistribusikan atau mengeksploitasi
                secara komersial atau membuat tersedia kepada pihak lain
                Aplikasi dan/atau perangkat lunak dengan cara menciptakan tautan
                (<em>link</em>) internet ke Aplikasi atau “<em>frame</em>” atau
                “<em>mirror</em>” setiap perangkat lunak pada server lain atau
                perangkat nirkabel atau yang berbasis internet;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Meluncurkan program otomatis atau script, termasuk, namun tidak
                terbatas pada, <em>web spiders</em>, <em>web crawlers</em>,{" "}
                <em>web robots</em>, <em>web ants</em>, <em>web indexers</em>,{" "}
                <em>bots</em>, <em>virus</em> atau <em>worm</em>, atau program
                apapun yang mungkin membuat beberapa permintaan server per
                detik, menciptakan beban berat atau menghambat operasi dan/atau
                kinerja Aplikasi;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Menggunakan aplikasi pencarian atau pengambilan kembali situs,
                perangkat manual atau otomatis lainnya untuk mengambil (
                <em>scraping</em>), indeks (<em>indexing</em>), survei (
                <em>surveying</em>), tambang data (<em>data mining</em>), atau
                dengan cara apapun memperbanyak atau menghindari struktur
                navigasi atau presentasi dari Aplikasi atau isinya;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Menerbitkan, mendistribusikan atau memperbanyak dengan cara
                apapun materi yang dilindungi hak cipta, merek dagang, atau
                informasi lain yang Kami miliki tanpa persetujuan tertulis
                terlebih dahulu dari Kami atau pemilik hak yang melisensikan
                hak-nya kepada Kami, dan
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Menggunakan dan/atau mengakses secara tidak resmi Aplikasi untuk
                (a) merusak, melemahkan atau membahayakan setiap aspek dari
                Aplikasi, Layanan atau sistem dan jaringan terkait, dan/atau (b)
                membuat produk atau layanan tandingan serupa menggunakan ide,
                fitur, fungsi atau grafik menyerupai Aplikasi.
              </p>
            </li>
          </ol>
        </div>

        {/* 10 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">10. Tanggung Jawab Anda</h5>
          <p className="body">
            Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk
            menggunakan atau mengakses Aplikasi, Layanan, Konten Pihak Ketiga,
            Penawaran atau Metode Pembayaran. Anda harus memperlakukan Penyedia
            Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran dan
            Penyedia Metode Pembayaran dengan hormat dan tidak boleh terlibat
            dalam perilaku atau tindakan yang tidak sah, mengancam atau
            melecehkan ketika menggunakan Layanan, Konten Pihak Ketiga,
            Penawaran atau Metode Pembayaran.
          </p>
          <p className="body">
            Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau
            klaim yang timbul dari penggunaan Aplikasi, Layanan, Konten Pihak
            Ketiga, Penawaran atau Metode Pembayaran melalui Akun Anda, baik
            oleh Anda atau pihak lain yang menggunakan Akun Anda, dengan cara
            yang bertentangan dengan Ketentuan Penggunaan ini, Kebijakan
            Privasi, termasuk syarat dan ketentuan dan kebijakan privasi yang
            ditentukan oleh Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran dan Penyedia Metode Pembayaran, atau peraturan
            perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk
            tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas
            kriminal, penipuan dalam bentuk apapun (termasuk namun tidak
            terbatas pada kegiatan phishing dan/atau social engineering),
            pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang
            merugikan publik dan/atau pihak lain manapun atau yang dapat atau
            dianggap dapat merusak reputasi Kami.
          </p>
        </div>

        {/* 11 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">11. Batasan Tanggung Jawab Kami</h5>
          <p className="body">
            Kami menyediakan Aplikasi sebagaimana adanya dan Kami tidak
            menyatakan atau menjamin bahwa keandalan, ketepatan waktu, kualitas,
            kesesuaian, ketersediaan, akurasi, kelengkapan atau keamanan dari
            Aplikasi dapat memenuhi kebutuhan dan akan sesuai dengan harapan
            Anda, termasuk namun tidak terbatas pada Layanan, Konten Pihak
            Ketiga, Penawaran dan Metode Pembayaran yang sepenuhnya menjadi
            tanggung jawab Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran dan Penyedia Metode Pembayaran. Kami tidak
            bertanggung jawab atas setiap kerugian atau kerusakan yang
            disebabkan oleh setiap kegagalan atau kesalahan yang dilakukan oleh
            Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran
            atau Penyedia Metode Pembayaran ataupun kegagalan atau kesalahan
            Anda dalam mematuhi Ketentuan Penggunaan Kami, Penyedia Layanan,
            Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia
            Metode Pembayaran.
          </p>
          <p className="body">
            Aplikasi dapat mengalami keterbatasan, penundaan, dan
            masalah-masalah lain yang terdapat dalam penggunaan internet dan
            komunikasi elektronik, termasuk perangkat Anda, Penyedia Layanan,
            Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau Penyedia
            Metode Pembayaran rusak, tidak terhubung dengan internet, berada di
            luar jangkauan, dimatikan atau tidak berfungsi. Kami tidak
            bertanggung jawab atas keterlambatan, kegagalan pengiriman,
            kerusakan atau kerugian yang diakibatkan oleh masalah-masalah
            tersebut.
          </p>
          <p className="body">
            Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda
            atas Aplikasi. Namun, Kami tetap melakukan pengawasan untuk tujuan
            memastikan kelancaran penggunaan Aplikasi dan untuk memastikan
            kepatuhan terhadap Ketentuan Penggunaan ini, peraturan
            perundang-undangan yang berlaku, putusan pengadilan, dan/atau
            ketentuan lembaga administratif atau badan pemerintahan lainnya.
          </p>
          <p className="body">
            Kami tidak mempunyai kewajiban apapun, termasuk untuk mengambil
            tindakan lebih jauh atau tindakan hukum yang dianggap perlu oleh
            Anda, Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia
            Penawaran atau Penyedia Metode Pembayaran, terhadap setiap
            permasalahan atau perselisihan yang timbul antara Anda dan Penyedia
            Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau
            Penyedia Metode Pembayaran. Tetapi, Kami akan memfasilitasi setiap
            permasalahan atau perselisihan yang timbul antara Anda dan Penyedia
            Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran atau
            Penyedia Metode Pembayaran dengan upaya wajar yang diperlukan.
            Ketika Kami memfasilitasi penyelesaian permasalahan atau
            perselisihan antara Anda dan Penyedia Layanan, Penyedia Konten Pihak
            Ketiga, Penyedia Penawaran atau Penyedia Metode Pembayaran, Kami
            tidak bertindak sebagai mediator dan hal tersebut tidak menimbulkan
            kewajiban lebih jauh apapun terhadap Kami.
          </p>
        </div>

        {/* 12 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">12. Penyelesaian Masalah</h5>
          <p className="body">
            Apabila Anda mengalami gangguan sistem, mengetahui atau menduga
            bahwa Akun Anda diretas, digunakan atau disalahgunakan oleh pihak
            lain, atau apabila perangkat telepon genggam atau tablet pribadi
            Anda hilang, dicuri, diretas atau terkena virus, segera laporkan
            kepada Kami sehingga Kami dapat segera mengambil tindakan yang
            diperlukan untuk menghindari penggunaan, penyalahgunaan, atau
            kerugian yang timbul atau mungkin timbul lebih lanjut.
          </p>
          <p className="body">
            Apabila Anda mengalami kendala atau masalah terkait Layanan, Konten
            Pihak Ketiga, Penawaran atau pembayaran melalui Metode Pembayaran,
            atau perlakuan Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran atau Penyedia Metode Pembayaran Anda dapat
            menyampaikan keluhan Anda melalui fitur yang Kami sediakan, termasuk
            pemberian peringkat dan komentar, atau dengan menghubungi Kami.
          </p>
          <p className="body">
            Untuk menyampaikan keluhan, pertanyaan, sanggahan, dan lain-lain (
            <span className="text-700">“Laporan”</span>), Anda perlu memberikan
            informasi yang cukup, termasuk namun tidak terbatas pada, ringkasan
            fakta yang terjadi, bukti-bukti yang Anda miliki, nomor pesanan, dan
            informasi pribadi, seperti alamat surat elektronik dan nomor telepon
            genggam terdaftar.
          </p>
          <p className="body">
            Untuk menanggapi setiap Laporan yang Anda sampaikan, Kami akan
            melakukan verifikasi terlebih dahulu dengan mencocokan informasi
            yang Anda berikan dan informasi pribadi Anda yang terdapat dalam
            sistem Kami. Jika diperlukan, Kami dapat secara langsung meminta
            Anda memberikan informasi yang diperlukan untuk tujuan verifikasi.
          </p>
          <p className="body">
            Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi
            yang Anda berikan tidak cocok dengan informasi pribadi yang terdapat
            dalam sistem Kami atau apabila Laporan disampaikan terkait,
            terhadap, atas nama atau oleh pihak lain yang berbeda dengan pemilik
            Akun yang bersangkutan yang terdaftar secara resmi pada sistem Kami.
            Kami dapat memberhentikan tindak lanjut terhadap Laporan Anda jika
            Kami, dengan kebijakan Kami sepenuhnya, menganggap bahwa Laporan
            Anda tidak didukung oleh fakta-fakta yang cukup dan jelas, atau
            telah selesai. Kami juga dapat meneruskan Laporan Anda kepada
            Penyedia Layanan, Penyedia Konten Pihak Ketiga, Penyedia Penawaran
            atau Penyedia Metode Pembayaran untuk diselesaikan secara langsung
            oleh Anda dan Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran atau Penyedia Metode Pembayaran.
          </p>
          <p className="body">
            Terhadap Laporan tertentu terkait Layanan, Kami dapat, dengan
            kebijakan Kami sepenuhnya, memfasilitasi pertemuan antara Anda dan
            Penyedia Layanan untuk tujuan penyelesaian masalah secara damai dan
            kekeluargaan. Dalam hal demikian, jika Anda merasa tetap perlu
            mengambil tindakan lain, termasuk tindakan hukum apapun, Anda dapat
            melakukannya atas tanggung jawab pribadi Anda sepenuhnya.
          </p>
          <p className="body">
            Terhadap Laporan tertentu terkait Metode Pembayaran, Kami dapat
            mengembalikan dana Anda melalui cara yang ditentukan oleh Kami atau
            bersama-sama dengan Penyedia Metode Pembayaran, berdasarkan
            kebijakan Kami sepenuhnya. Kami tidak akan mengembalikan dana kepada
            pihak lain yang berbeda dengan kredensial terdaftar pada Akun
            Sociopipe atau Penyedia Metode Pembayaran, termasuk apabila Anda
            menggunakan kredensial pihak lain untuk melakukan pembayaran melalui
            Metode Pembayaran.
          </p>
        </div>

        {/* 13 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">
            13. Pembekuan Sementara dan Pembekuan Permanen Akun
          </h5>
          <p className="body">
            Anda dapat menghapus Aplikasi dari telepon genggam dan/atau tablet
            Anda setiap saat. Kami tidak memiliki kewajiban apapun kepada Anda
            terhadap hal-hal yang timbul sejak penghapusan Aplikasi, pembekuan
            sementara atau pembekuan permanen Akun Anda. Akan tetapi, Anda tetap
            bertanggung jawab untuk menyelesaikan kewajiban-kewajiban Anda yang
            telah timbul, termasuk namun tidak terbatas pada, setiap kewajiban
            yang mungkin timbul akibat adanya sengketa, tuntutan, maupun
            tindakan hukum lainnya yang telah ada, sebelum tanggal penghapusan
            Aplikasi, pembekuan sementara atau pembekuan permanen Akun Anda.
          </p>
          <p className="body mb-2">
            Akun Anda dapat dibekukan untuk sementara waktu atau dapat dibekukan
            secara permanen karena hal-hal, termasuk namun tidak terbatas pada,
            sebagai berikut:
          </p>
          <ol>
            <li>
              <p className="body mb-0">
                Laporan Anda bahwa Akun Anda digunakan atau diduga digunakan
                atau disalahgunakan oleh orang lain;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Laporan Anda bahwa telepon genggam atau tablet pribadi Anda
                hilang, dicuri atau diretas;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Kami mengetahui atau mempunyai alasan yang cukup untuk menduga
                bahwa Akun Anda telah dialihkan atau digunakan oleh orang lain;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Kami mengetahui atau dengan alasan yang cukup menduga bahwa
                telah terjadi hal-hal yang menurut pandangan Kami telah atau
                dapat merugikan Kami, Anda, Penyedia Layanan atau pihak lainnya;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Kami mengetahui atau dengan alasan yang cukup menduga bahwa Anda
                telah mendaftar atau masuk dalam banyak Akun dalam satu
                perangkat untuk tujuan atau dicurigai ingin melanggar Ketentuan
                Penggunaan, Kebijakan Privasi atau peraturan dan hukum yang
                berlaku.
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Sistem Kami mendeteksi adanya tindakan yang tidak wajar dari
                penggunaan Akun Anda atau adanya kewajiban berdasarkan Ketentuan
                Penggunaan, dan/atau Kebijakan Privasi yang tidak dipenuhi oleh
                Anda;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Anda telah meninggal dunia, ditempatkan di bawah perwalian atau
                pengampuan atau mengalami ketidakmampuan lainnya yang menjadikan
                Anda tidak cakap hukum berdasarkan peraturan perundang-undangan
                yang berlaku;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Penggunaan Aplikasi atau Layanan oleh Anda atau pihak lain (yang
                menggunakan Akun Anda) dengan cara yang bertentangan dengan
                Ketentuan Penggunaan ini, Kebijakan Privasi atau peraturan
                perundang-undangan yang berlaku; dan/atau
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Perintah untuk pembekuan akun, baik sementara atau permanen,
                yang diterbitkan oleh institusi pemerintah atau moneter terkait
                atau berdasarkan perintah pengadilan yang diterbitkan sesuai
                dengan peraturan perundang-undangan yang berlaku.
              </p>
            </li>
          </ol>
          <p className="body">
            Jika Akun Anda dibekukan dan Anda memiliki bukti yang jelas bahwa
            Akun Anda seharusnya tidak dibekukan, Anda dapat membuat Laporan
            kepada Kami untuk menyampaikan bukti-bukti tersebut. Setelah
            melakukan pemeriksaan lebih lanjut terhadap Laporan Anda, Kami akan,
            atas kebijakan Kami sepenuhnya, menentukan untuk mengakhiri atau
            melanjutkan pembekuan terhadap Akun Anda. Pembekuan tidak akan
            diteruskan secara tidak wajar apabila Kami memutuskan bahwa hal-hal
            yang mengakibatkan terjadinya pembekuan telah diselesaikan.
          </p>
          <p className="body">
            Anda tidak akan menggunakan Aplikasi pada perangkat atau sistem
            operasi yang telah dimodifikasi diluar perangkat atau konfigurasi
            sistem operasi dan konfigurasi Sociopipe. Hal ini mencakup perangkat
            yang telah melalui proses “<em>rooted</em>” atau “
            <em>jail-broken</em>”. Perangkat <em>rooted</em> atau{" "}
            <em>jail-broken</em> adalah perangkat yang telah dibebaskan dari
            pembatasan yang dikenakan oleh penyedia layanan perangkat dan yang
            dimanufaktur tanpa persetujuan penyedia layanan perangkat.
            Penggunaan Aplikasi pada perangkat <em>rooted</em> atau{" "}
            <em>jail-broken</em> dapat mengkompromisasi keamanan dan berujung
            pada transaksi penipuan.
          </p>
          <p className="body">
            Kami tidak bertanggung jawab atas pengunduhan dan penggunaan
            Aplikasi pada perangkat <em>rooted</em> atau <em>jail-broken</em>{" "}
            dan resiko penggunaan Anda terhadap perangkat <em>rooted</em> atau{" "}
            <em>jail-broken</em> sepenuhnya adalah resiko Anda. Anda mengerti
            dan setuju bahwa Kami tidak bertanggung jawab atas segala kehilangan
            atau setiap konsekuensi lain yang diderita atau disebabkan oleh Anda
            sebagai akibat dari penggunaan aplikasi Sociopipe dan
            afiliasi-afiliasi pada perangkat <em>rooted</em> atau{" "}
            <em>jail-broken</em> dan Kami mempunyai diskresi untuk menghentikan
            Penggunaan Anda terhadap Aplikasi pada perangkat <em>rooted</em>{" "}
            atau <em>jail-broken</em> dan memblokir perangkat <em>rooted</em>{" "}
            atau <em>jail-broken</em> untuk menggunakan Aplikasi.
          </p>
        </div>

        {/* 14 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">14. Tindakan Yang Kami Anggap Perlu</h5>
          <p className="body">
            Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk
            menduga bahwa Anda telah melakukan tindakan asusila, pelanggaran,
            kejahatan atau tindakan lain yang bertentangan dengan Ketentuan
            Penggunaan ini dan/atau peraturan perundang-undangan yang berlaku,
            baik yang dirujuk dalam Ketentuan Penggunaan ini atau tidak, maka
            Kami berhak untuk dan dapat membekukan Akun, baik sementara atau
            permanen, atau menghentikan akses Anda terhadap Aplikasi, termasuk
            Layanan, Konten Pihak Ketiga, Penawaran dan/atau Metode Pembayaran
            yang terdapat di dalamnya, melakukan pemeriksaan, menuntut ganti
            kerugian, melaporkan kepada pihak berwenang dan/atau mengambil
            tindakan lain yang kami anggap perlu, termasuk tindakan hukum pidana
            maupun perdata.
          </p>
          <p className="body mb-2">
            Kami akan menindaklanjuti dengan melakukan investigasi dan/atau
            memfasilitasi Penyedia Layanan yang bersangkutan untuk melaporkan
            kepada pihak yang berwajib apabila Kami menerima Laporan adanya
            pelanggaran yang Anda lakukan atas Ketentuan Penggunaan ini ataupun
            pelanggaran terhadap peraturan perundang-undangan yang berlaku,
            sehubungan dengan:
          </p>
          <ol>
            <li>
              <p className="body mb-0">
                Pelecehan atau kekerasan verbal, termasuk namun tidak terbatas
                pada, atas fisik, jenis kelamin, suku, agama dan ras, yang Anda
                lakukan terhadap Penyedia Layanan;
              </p>
            </li>
            <li>
              <p className="body mb-0">Tindakan penipuan;</p>
            </li>
            <li>
              <p className="body mb-0">
                Penggunaan Aplikasi pada perangkat <em>rooted</em> atau{" "}
                <em>jail-broken</em>;
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Pelecehan atau kekerasan fisik yang Anda lakukan terhadap
                Penyedia Layanan; dan/atau
              </p>
            </li>
            <li>
              <p className="body mb-0">
                Pelecehan atau kekerasan seksual, baik secara verbal maupun
                fisik, yang Anda lakukan terhadap Penyedia Layanan.
              </p>
            </li>
          </ol>
          <p className="body">
            Ketentuan ini juga berlaku sebaliknya apabila Anda mengalami
            tindakan sebagaimana disebutkan di atas yang dilakukan oleh Penyedia
            Layanan terhadap Anda.
          </p>
        </div>

        {/* 15 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">15. Pernyataan Anda</h5>
          <p className="body">
            Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18
            (delapan belas) tahun atau sudah menikah dan tidak berada di bawah
            perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas
            dan berhak untuk mengikatkan diri pada Ketentuan Penggunaan ini.
            Jika Anda tidak memenuhi ketentuan tersebut namun tetap mengakses
            atau menggunakan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran
            atau Metode Pembayaran, Anda menyatakan dan menjamin bahwa tindakan
            Anda membuka, mengakses atau melakukan aktivitas lain dalam Aplikasi
            telah disetujui oleh orang tua, wali atau pengampu Anda. Anda secara
            tegas mengesampingkan setiap hak berdasarkan hukum untuk membatalkan
            atau mencabut setiap dan seluruh persetujuan yang Anda berikan
            berdasarkan Ketentuan Penggunaan ini pada waktu Anda dianggap telah
            dewasa secara hukum.
          </p>
          <p className="body">
            Anda setuju untuk mengakses atau menggunakan Aplikasi, Layanan,
            Konten Pihak Ketiga, Penawaran atau Metode Pembayaran hanya untuk
            tujuan sebagaimana ditentukan dalam Ketentuan Penggunaan ini dan
            tidak menyalahgunakan atau menggunakan Aplikasi, Layanan, Konten
            Pihak Ketiga, Penawaran atau Metode Pembayaran untuk tujuan
            penipuan, menyebabkan ketidaknyamanan kepada orang lain atau yang
            tindakan-tindakan lain yang dapat atau dianggap dapat menimbulkan
            kerugian dalam bentuk apapun terhadap orang lain.
          </p>
          <p className="body">
            Anda memahami dan menyetujui bahwa seluruh resiko yang timbul dari
            penggunaan Aplikasi, Layanan, Konten Pihak Ketiga, Penawaran dan
            Metode Pembayaran sepenuhnya menjadi tanggung jawab Anda dan Anda
            dengan ini setuju untuk melepaskan Kami dari segala tuntutan apapun
            sehubungan dengan kerusakan, gangguan, atau bentuk lain dari
            gangguan sistem yang disebabkan oleh akses tidak resmi oleh pihak
            lain.
          </p>
          <p className="body">
            Anda secara tegas membebaskan Kami, termasuk namun tidak terbatas
            pada, pejabat, direktur, komisaris, karyawan dan agen Kami, dari
            dari setiap dan semua kewajiban, konsekuensi, kerugian baik materiil
            atau immateriil, tuntutan, biaya-biaya (termasuk biaya advokat) atau
            tanggung jawab hukum lainnya yang timbul atau mungkin timbul akibat
            pelanggaran Anda terhadap Ketentuan Penggunaan ini maupun sehubungan
            dengan tindakan Penyedia Layanan, Penyedia Konten Pihak Ketiga,
            Penyedia Penawaran atau Penyedia Metode Pembayaran.
          </p>
          <p className="body">
            Dikecualikan dari ketentuan di atas, jika terdapat kerugian langsung
            akibat pelanggaran Kami terhadap Ketentuan Penggunaan ini, maka Anda
            setuju dan secara tegas membatasi jumlah tuntutan Anda sebesar
            jumlah keseluruhan yang senyatanya ditimbulkan atau telah dibayarkan
            sehubungan dengan peristiwa tersebut.
          </p>
          <p className="body">
            DENGAN MELANJUTKAN AKSES ATAU PENGGUNAAN TERHADAP APLIKASI SOCIOPIPE
            BESERTA AFILIASI-AFILIASI DAN/ATAU LAYANAN, ANDA SETUJU UNTUK TUNDUK
            DAN MEMATUHI SEMUA KETENTUAN PERATURAN PERUNDANG-UNDANGAN TERKAIT
            DAN KETENTUAN PENGGUNAAN INI, TERMASUK SEMUA PERUBAHANNYA DAN
            KETENTUAN PENGGUNAAN DARI SETIAP PENYEDIA LAYANAN, PENYEDIA KONTEN
            PIHAK KETIGA, PENYEDIA PENAWARAN ATAU PENYEDIA METODE PEMBAYARAN.
            SEGERA HENTIKAN AKSES ATAU PENGGUNAAN APLIKASI SOCIOPIPE BESERTA
            AFILIASI-AFILIASI DAN/ATAU LAYANAN JIKA ANDA TIDAK SETUJU DENGAN
            BAGIAN APAPUN DARI KETENTUAN PENGGUNAAN INI.
          </p>
        </div>

        {/* 16 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">16. Keadaan Kahar</h5>
          <p className="body">
            Aplikasi dapat diinterupsi oleh kejadian di luar kewenangan atau
            kontrol Kami (“<span className="text-700">Keadaan Kahar</span>”),
            termasuk namun tidak terbatas pada bencana alam, gangguan listrik,
            gangguan telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda
            setuju untuk membebaskan Kami dari setiap tuntutan dan tanggung
            jawab, jika Kami tidak dapat memfasilitasi Layanan, termasuk
            memenuhi instruksi yang Anda berikan melalui Aplikasi, baik sebagian
            maupun seluruhnya, karena suatu Keadaan Kahar.
          </p>
        </div>

        {/* 17 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">17. Lain-Lain</h5>
          <p className="body">
            Anda mengerti dan setuju bahwa Ketentuan Penggunaan ini merupakan
            perjanjian dalam bentuk elektronik dan tindakan Anda menekan tombol
            ‘daftar’ saat pembukaan Akun atau tombol ‘masuk’ saat akan mengakses
            Akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri
            dalam perjanjian dengan Kami sehingga keberlakuan Ketentuan
            Penggunaan ini dan Kebijakan Privasi adalah sah dan mengikat secara
            hukum dan terus berlaku sepanjang penggunaan Aplikasi dan Layanan
            oleh Anda.
          </p>
          <p className="body">
            Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap
            keabsahan dari Ketentuan Penggunaan atau Kebijakan Privasi yang
            dibuat dalam bentuk elektronik.
          </p>
          <p className="body">
            Anda tidak dapat mengalihkan hak Anda berdasarkan Ketentuan
            Penggunaan ini tanpa persetujuan tertulis sebelumnya dari Kami.
            Namun, Kami dapat mengalihkan hak Kami berdasarkan Ketentuan
            Penggunaan ini setiap saat kepada pihak lain tanpa perlu mendapatkan
            persetujuan terlebih dahulu dari atau memberikan pemberitahuan
            sebelumya kepada Anda.
          </p>
          <p className="body">
            Bila Anda tidak mematuhi atau melanggar ketentuan dalam Ketentuan
            Penggunaan ini, dan Kami tidak mengambil tindakan secara langsung,
            bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan
            yang diperlukan di kemudian hari.
          </p>
          <p className="body">
            Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara,
            pembekuan permanen, penghapusan Aplikasi atau setelah berakhirnya
            perjanjian ini antara Anda dan Kami.
          </p>
          <p className="body">
            Jika salah satu dari ketentuan dalam Ketentuan Penggunaan ini tidak
            dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan
            lainnya.
          </p>
        </div>

        {/* 18 */}
        <div className="terms-and-conditions__item pad-t-10">
          <h5 className="heading-5">18. Cara Menghubungi Kami</h5>
          <p className="body">
            Anda dapat menghubungi Kami melalui surat elektronik ke{" "}
            <a
              href="mailto:support@sociopipe.com"
              className="terms-and-conditions__link"
            >
              support@sociopipe.com
            </a>
            . Semua korespondensi Anda akan dicatat, direkam dan disimpan untuk
            arsip Kami.
          </p>
          <p className="body">
            Saya telah membaca dan mengerti seluruh Ketentuan Penggunaan ini dan
            konsekuensinya dan dengan ini menerima setiap hak, kewajiban, dan
            ketentuan yang diatur di dalamnya.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
